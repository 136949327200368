<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작성기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="1M"
            label="작성기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            type="user" 
            label="소방안전관리자" 
            name="seniorUserId" 
            v-model="searchParam.seniorUserId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방 훈련·교육 실시결과기록부 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "fireFightingPerformanceResult",
  data() {
    return {
      searchParam: {
        plantCd: null,
        period: [],
        seniorUserId: '',
      },
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "recordName",
            field: "recordName",
            label: "실기결과기록부명",
            style: 'width:150px',
            align: "left",
            type: "link",
            sortable: true,
          },
          {
            name: "regDtStr",
            field: "regDtStr",
            label: "작성일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "fireManager",
            field: "fireManager",
            label: "소방안전관리자",
            child: [
              {
                name: "seniorUserName",
                field: "seniorUserName",
                label: "선임자",
                style: 'width:100px',
                align: "center",
                sortable: true,
              },
              {
                name: "seniorDate",
                field: "seniorDate",
                label: "선임일",
                style: 'width:100px',
                align: "center",
                sortable: true,
              },
            ],
          },
          {
            name: "education",
            field: "education",
            label: "교육",
            child: [
              {
                name: "educationTypeName",
                field: "educationTypeName",
                label: "교육구분",
                style: 'width:100px',
                align: "center",
                sortable: true,
                type: 'link'
              },
              {
                name: "educationPeriod",
                field: "educationPeriod",
                label: "교육일정",
                style: 'width:100px',
                align: "center",
                sortable: true,
              },
            ],
          },
          {
            name: "training",
            field: "training",
            label: "훈련",
            child: [
              {
                name: "eapTrainingTypeName",
                field: "eapTrainingTypeName",
                label: "훈련구분",
                style: 'width:100px',
                align: "center",
                sortable: true,
                type: 'link'
              },
              {
                name: "trainingPeriod",
                field: "trainingPeriod",
                label: "훈련일정",
                style: 'width:100px',
                align: "center",
                sortable: true,
              },
            ],
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingPerformanceResult.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openPop() {
      this.linkClick(null, { name: 'recordName' });
    },
    linkClick(row, col) {
      if (col.name === 'recordName') {
        // 실시결과기록부
        this.popupOptions.title = "소방 훈련·교육 실시결과기록부 상세"; // 소방 훈련·교육 실시결과기록부 상세
        this.popupOptions.param = {
          sopFireFightingPerformanceResultRecordId: row ? row.sopFireFightingPerformanceResultRecordId : '',
        };
        this.popupOptions.target = () => import(`${"./fireFightingPerformanceResultDetail.vue"}`);
      } else if (col.name === 'educationTypeName') {
        // 교육
        this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 상세
        this.popupOptions.param = {
          eduEducationId: row.eduEducationId,
          stepCd: row.stepCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      } else if (col.name === 'eapTrainingTypeName') {
        // 훈련
        this.popupOptions.title = 'LBL0002663'; // 비상대응 훈련 상세
        this.popupOptions.param = {
          sopEmergencyTrainingId: row.sopEmergencyTrainingId
        }
        this.popupOptions.target = () => import(`${'@/pages/sop/eap/traningDetail.vue'}`);
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
